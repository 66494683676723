











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class VdHeader extends Vue {
  @Prop({ default: false }) private showMenuButton!: boolean;

  @Prop({ default: '#f8f8f8' }) private pageBackground!: string;

  @Prop({ default: 'white' }) private background!: string;

  @Prop({ default: '300px' }) private sidebarWidth!: string;

  get mapCssProps() {
    const result: Record<string, string> = {};
    result['--vd-page-background'] = this.pageBackground;
    result['--vd-header-background'] = this.background;
    result['--vd-sidebar-width'] = this.sidebarWidth;
    return result;
  }

  openMenu() {
    this.$emit('open-menu');
  }
}
