


































import {
  Component, Prop, Watch, Vue,
} from 'vue-property-decorator';
import VdHeader from '../VdHeader/VdHeader.vue';
import VdSidebar from '../VdSidebar/VdSidebar.vue';

@Component({
  components: {
    VdHeader,
    VdSidebar,
  },
})
export default class VdDashboard extends Vue {
  @Prop({ default: '50px' }) private sidebarHeaderHeight!: string;

  @Prop({ default: '300px' }) private sidebarWidth!: string;

  @Prop({ default: '#f8f8f8' }) private pageBackground!: string;

  @Prop({ default: 'white' }) private headerBackground!: string;

  @Prop({ default: 'white' }) private sidebarBackground!: string;

  sidebarIsVisible = true;

  isCollapse = false;

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.onResize();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }

  @Watch('$route')
  onRouteChange() {
    this.pageClick();
  }

  onResize() {
    if (window.innerWidth <= 1200) {
      this.sidebarIsVisible = false;
      this.isCollapse = true;
    } else {
      this.sidebarIsVisible = true;
      this.isCollapse = false;
    }
  }

  openMenu() {
    this.sidebarIsVisible = true;
  }

  closeSidebar() {
    this.sidebarIsVisible = false;
  }

  pageClick() {
    if (this.isCollapse && this.sidebarIsVisible) {
      this.closeSidebar();
    }
  }

  get mapCssProps() {
    const result: Record<string, string> = {};
    result['--vd-page-background'] = this.pageBackground;
    result['--vd-sidebar-width'] = this.sidebarWidth;
    return result;
  }
}
