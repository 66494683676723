




















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class VdSidebar extends Vue {
  @Prop({ default: true }) private isVisible!: boolean;

  @Prop({ default: false }) private showCloseButton!: boolean;

  @Prop({ default: '50px' }) private headerHeight!: string;

  @Prop({ default: '300px' }) private sidebarWidth!: string;

  @Prop({ default: 'white' }) private background!: string;

  get mapCssProps() {
    const result: Record<string, string> = {};
    result['--vd-header-height'] = this.headerHeight;
    result['--vd-sidebar-background'] = this.background;
    result['--vd-sidebar-width'] = this.sidebarWidth;
    return result;
  }

  closeSidebar() {
    this.$emit('close-sidebar');
  }
}
